import React from 'react';

const Home = () => (
  <div className="home main">
    <div className="home title">Welcome to our page</div>
    <div className="home subtitle">
      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempora
      repellendus iste, reprehenderit, quam dignissimos explicabo aperiam
      quisquam voluptatibus molestias aliquam natus commodi necessitatibus cum
      quibusdam sapiente ipsa assumenda aut eius!
    </div>
    <div className="home subtitle">
      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempora
      repellendus iste, reprehenderit, quam dignissimos explicabo aperiam
      quisquam voluptatibus molestias aliquam natus commodi necessitatibus cum
      quibusdam sapiente ipsa assumenda aut eius!
    </div>
  </div>
);
export default Home;
